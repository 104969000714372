<template>
  <v-container>

    <!-- new series -->
    <v-card outlined class="mb-3">
      <v-toolbar flat dense color="primary">
        <v-toolbar-title>Stwórz nowy cykl zawodów</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-row>
          <v-col>
            <v-text-field dense outlined v-model="newId" counter="8" label="Identyfikator cyklu"
                          hint="Po utworzeniu cyklu zawodów nie można zmienić identyfikatora"/>
          </v-col>
          <v-col>
            <v-text-field dense outlined v-model="newPlaces" type="number" min="1" max="500" step="1"
                          label="Liczba miejsc punktujących" hint="1 - 500"/>
          </v-col>
        </v-row>
        <v-text-field dense outlined v-model="newName" counter="60" label="Nazwa cyklu"/>
        <v-divider class="mb-6"/>
        <v-text-field v-for="(place, index) in newPoints" :key="index" dense outlined
                      v-model="newPoints[index]" :label="(index + 1) + '. miejsce'" type="number" min="1" max="10000"
                      :value="place"/>
        <v-btn elevation="0" color="primary" @click="createSeries()">STWÓRZ CYKL ZAWODÓW</v-btn>
      </v-card-text>
    </v-card>

    <!-- all series -->
    <v-card outlined class="mb-3">
      <v-toolbar flat dense color="primary">
        <v-toolbar-title>Cykle zawodów</v-toolbar-title>
        <v-spacer/>
        <v-text-field flat dense hide-details solo v-model="search" label="Szukaj" prepend-inner-icon="mdi-magnify"/>
      </v-toolbar>
      <v-card-text>
        <v-data-table dense :headers="headers" :items="series" item-key="series_id" :loading="loading" :search="search">
          <!--suppress HtmlUnknownAttribute -->
          <template v-slot:item.live_comp="{item}">
            <v-icon :color="item['live_comp'] != null ? 'success' : 'error'">
              {{ item['live_comp'] != null ? 'mdi-check' : 'mdi-close' }}
            </v-icon>
          </template>
          <!--suppress HtmlUnknownAttribute -->
          <template v-slot:item.options="{item}">
            <v-icon left @click="$router.push('/admin/rankings/series/edit/' + item['series_id'])">mdi-pencil</v-icon>
            <v-icon left @click="preDeleteSeries(item['series_id'])">mdi-delete</v-icon>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <!-- alerts -->
    <Alert ref="alert"/>
    <ConfirmationAlert ref="confirmation"/>

  </v-container>
</template>

<script>
import Alert from '../components/Alert';
import ConfirmationAlert from '../components/ConfirmationAlert';

export default {
  name: 'AdminRankingsSeries',
  components: {Alert, ConfirmationAlert},
  data: () => ({
    newId: '', newName: '', newPlaces: '1', newPoints: ['1'],
    headers: [
      {text: 'Nazwa', align: 'start', sortable: true, value: 'series_name'},
      {text: 'Identyfikator', align: 'start', sortable: true, value: 'series_id'},
      {text: 'Zawody live', align: 'center', sortable: false, value: 'live_comp'},
      {text: '', align: 'right', sortable: false, value: 'options'}
    ],
    series: [], loading: true, search: '', deleteId: ''
  }),
  created() {
    this.create();
  },
  methods: {
    create() {
      this.$http.post('/api/admin/series/get').then((res) => {
        this.series = res.data;
      }).catch((e) => {
        console.log(e);
      }).finally(() => {
        this.loading = false;
      });
    },
    createSeries() {
      const id = this.newId, name = this.newName, points = this.newPoints.map((item) => parseInt(item));
      this.$http.post('/api/admin/series/create', {id, name, points}).then(() => {
        this.$refs.alert.show('Sukces', 'Pomyślnie utworzono nowy cykl zawodów.', 'success');
        this.newId = '';
        this.newName = '';
        this.newPlaces = '1';
        this.newPoints = ['1'];
        this.create();
      }).catch((e) => {
        this.$refs.alert.show('Błąd', 'Nowy cykl zawodów nie został utworzony.', 'error');
        console.log(e);
      });
    },
    preDeleteSeries(id) {
      this.deleteId = id;
      this.$refs.confirmation.show(id, 'Czy na pewno chcesz usunąć cykl zawodów o podanym identyfikatorze?', 'error', this.deleteSeries);
    },
    deleteSeries() {
      this.$http.post('/api/admin/series/delete', {id: this.deleteId}).then(() => {
        this.$refs.alert.show('Sukces', 'Pomyślnie usunięto cykl zawodów.', 'success');
        this.create();
      }).catch((e) => {
        this.$refs.alert.show('Błąd', 'Cykl zawodów nie został usunięty.', 'error');
        console.log(e);
      });
    }
  },
  watch: {
    newPlaces: function (value) {
      while (this.newPoints.length < value)
        this.newPoints.push('1')
      while (this.newPoints.length > value)
        this.newPoints.pop();
    }
  }
};
</script>
